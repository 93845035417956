import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OrderDetail } from '../models/order-detail.model';
import { Order } from '../models/order.model';
import { ServiceType } from '../models/service-type.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ActivityType } from '../models/activity-type.model';
import { ItemType } from '../models/item-type.model';
import { ServiceOrderStatus } from '../models/service-order-status.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  _orders: Order[] = [];
  _service_order_status: ServiceOrderStatus[] = [];
  searchText: string = '';
  page: number = 0;
  take: number = 15;
  total: number;
  contador = 0;
  private _serviceTypes: ServiceType[] = [];
  private _activityTypes: ActivityType[] = [];
  private _itemTypes: ItemType[] = [];
  private _newOrder: Order = new Order();

  constructor(
    private http: HttpClient,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private authService: AuthService
  ) {}

  get orders() {
    return this._orders;
  }

  canSearch(): boolean {
    return !this.total || this.total > this.page * this.take;
  }

  // resetSearch(searchText: string, idServiceOrderStatus: number) {
  resetSearch(searchText: string) {
    this.searchText = searchText;
    this._orders = [];
    this.total = null;
    this.page = 0;
    if (!this.canSearch()) {
      return;
    }
    this.page++;
    // return this.getOrders(this.searchText, idServiceOrderStatus);
    return this.getOrders(this.searchText);
  }

  // search(searchText: string, idServiceOrderStatus: number) {
  search(searchText: string) {
    if (this.searchText !== searchText) {
      this.searchText = searchText;
      this._orders = [];
      this.total = null;
      this.page = 0;
    }
    if (!this.canSearch()) {
      return;
    }
    this.page++;
    // return this.getOrders(this.searchText, idServiceOrderStatus);
    return this.getOrders(this.searchText);
  }

  // private getOrders(searchText: string, idServiceOrderStatus: number) {
  private getOrders(searchText: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        // 'ServiceOrder/getAllServiceOrderByServiceOrderStatusAndUser?page=' +
        'ServiceOrder/getAllServiceOrderByUser?page=' +
        this.page +
        '&take=' +
        this.take +
        // '&idServiceOrderStatus=' +
        // idServiceOrderStatus +
        '&idUser=' +
        this.authService.getUser().idUser +
        '&searchText=' +
        (searchText ? searchText : '');

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._orders = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._orders = [...this._orders, ...resultado.data];
            this.total = resultado.total;
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  // getOrder(id: number) {
  //   return { ...this._orders.find((p) => p.idWorkOrder === id) };
  // }

  getOrder(id: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceOrder/getServiceOrderInformation?idServiceOrder=' +
        id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          // if (!resultado.data) {
          //   this._work_order_status = [];
          //   resolve();
          // } else {
          //   // si se obtienen los datos del usuario
          //   this._orders = [...this._orders, ...resultado.data];
          resolve(resultado.data);
          // }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve(null);
        }
      );
    });

    return promesa;
  }

  // addFakeOrders() {
  //   let hoy = new Date();
  //   const detalleorden: OrderDetail[] = [
  //     {
  //       id: 1,
  //       descripcion: 'Calcetines',
  //       cantidad: 2,
  //       preciounitario: 1.5,
  //       itemType: null
  //     },
  //     {
  //       id: 2,
  //       descripcion: 'Prenda íntima',
  //       cantidad: 4,
  //       preciounitario: 1.5,
  //       itemType: null
  //     },
  //     {
  //       id: 3,
  //       descripcion: 'Camisas',
  //       cantidad: 1,
  //       preciounitario: 1.5,
  //       itemType: null
  //     }
  //   ];

  //   // Registro 1
  //   this.contador++;
  //   const orden1: Order = {
  //     id: this.contador,
  //     codigo: 'OS' + this.padLeft(this.contador.toString(), '0', 5),
  //     fechapedido: hoy,
  //     locker: {
  //       idLocker: 1,
  //       lockerNumber: '0001',
  //       idLockerPoint: 1,
  //       address: 'direccion',
  //       reference: 'referencia',
  //       idSite: 22,
  //       siteCode: 'SIS',
  //       siteDescription: 'SAN ISIDRO',
  //       latitude: 47.651,
  //       longitude: -122.349
  //     },
  //     clave: '5262',
  //     usercard: null,
  //     numerotarjeta: '**** **** **** 0727',
  //     tokentarjeta: '**** **** **** 0727',
  //     total: 0,
  //     estado: 'Pedido registrado',
  //     icono: 'assets/icon/armario.png',
  //     serviceType: null,
  //     detalle: []
  //   };

  //   this._orders.push(orden1);
  //   // Registro 2
  //   this.contador++;
  //   const orden2: Order = {
  //     id: this.contador,
  //     codigo: 'OS' + this.padLeft(this.contador.toString(), '0', 5),
  //     fechapedido: this.addDays(hoy, -1),
  //     locker: {
  //       idLocker: 1,
  //       lockerNumber: '0001',
  //       idLockerPoint: 1,
  //       address: 'direccion',
  //       reference: 'referencia',
  //       idSite: 22,
  //       siteCode: 'SIS',
  //       siteDescription: 'SAN ISIDRO',
  //       latitude: 47.651,
  //       longitude: -122.349
  //     },
  //     clave: '5262',
  //     usercard: null,
  //     numerotarjeta: '**** **** **** 0727',
  //     tokentarjeta: '**** **** **** 0727',
  //     total: 0,
  //     estado: 'Recogiendo',
  //     icono: 'assets/icon/camion_recojo.png',
  //     serviceType: null,
  //     detalle: []
  //   };

  //   this._orders.push(orden2);

  //   // Registro 3
  //   this.contador++;
  //   const orden3: Order = {
  //     id: this.contador,
  //     codigo: 'OS' + this.padLeft(this.contador.toString(), '0', 5),
  //     fechapedido: this.addDays(hoy, -2),
  //     locker: {
  //       idLocker: 1,
  //       lockerNumber: '0001',
  //       idLockerPoint: 1,
  //       address: 'direccion',
  //       reference: 'referencia',
  //       idSite: 22,
  //       siteCode: 'SIS',
  //       siteDescription: 'SAN ISIDRO',
  //       latitude: 47.651,
  //       longitude: -122.349
  //     },
  //     clave: '5262',
  //     usercard: null,
  //     numerotarjeta: '**** **** **** 0727',
  //     tokentarjeta: '**** **** **** 0727',
  //     total: 20,
  //     estado: 'En proceso',
  //     icono: 'assets/icon/lavadora.png',
  //     serviceType: null,
  //     detalle: detalleorden
  //   };

  //   this._orders.push(orden3);

  //   // Registro 4
  //   this.contador++;
  //   const orden4: Order = {
  //     id: this.contador,
  //     codigo: 'OS' + this.padLeft(this.contador.toString(), '0', 5),
  //     fechapedido: this.addDays(hoy, -3),
  //     locker: {
  //       idLocker: 1,
  //       lockerNumber: '0001',
  //       idLockerPoint: 1,
  //       address: 'direccion',
  //       reference: 'referencia',
  //       idSite: 22,
  //       siteCode: 'SIS',
  //       siteDescription: 'SAN ISIDRO',
  //       latitude: 47.651,
  //       longitude: -122.349
  //     },
  //     clave: '5262',
  //     usercard: null,
  //     numerotarjeta: '**** **** **** 0727',
  //     tokentarjeta: '**** **** **** 0727',
  //     total: 20,
  //     estado: 'Entregando',
  //     icono: 'assets/icon/camion_reparto.png',
  //     serviceType: null,
  //     detalle: detalleorden
  //   };

  //   this._orders.push(orden4);

  //   // Registro 5
  //   this.contador++;
  //   const orden5: Order = {
  //     id: this.contador,
  //     codigo: 'OS' + this.padLeft(this.contador.toString(), '0', 5),
  //     fechapedido: this.addDays(hoy, -4),
  //     locker: {
  //       idLocker: 1,
  //       lockerNumber: '0001',
  //       idLockerPoint: 1,
  //       address: 'direccion',
  //       reference: 'referencia',
  //       idSite: 22,
  //       siteCode: 'SIS',
  //       siteDescription: 'SAN ISIDRO',
  //       latitude: 47.651,
  //       longitude: -122.349
  //     },
  //     clave: '5262',
  //     usercard: null,
  //     numerotarjeta: '**** **** **** 0727',
  //     tokentarjeta: '**** **** **** 0727',
  //     total: 20,
  //     estado: 'En el locker',
  //     icono: 'assets/icon/armario_clave.png',
  //     serviceType: null,
  //     detalle: detalleorden
  //   };

  //   this._orders.push(orden5);
  // }

  register(order: any) {
    let promesa = new Promise((resolve, reject) => {
      // let postData = {
      //   idUser: 0,
      //   userAccount: 'jvalencia3005@gmail.com',
      //   password: 'string',
      //   firstName: 'string',
      //   lastName: 'string',
      //   email: 'string',
      //   phone: 'string',
      //   mobile: 'string',
      //   tokenFCM: 'string',
      //   rolType: 'C',
      //   isActive: false,
      //   rowStatus: true
      // };

      const url = environment.urlApi + 'serviceorder/confirmServiceOrder';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.post(url, order, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);

          // console.log(res.isValid);

          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se registro satisfactoriamente
            resolve(resultado.data);
          }
        },
        async (error: any) => {
          // this.activo = this.checkActivo();

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo completar el registro.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          reject();
        }
      );
    });

    return promesa;
  }

  addOrder(order: Order) {
    this._orders.push(order);
  }

  removeOrder(order: Order) {
    this._orders = this._orders.filter((item) => {
      return item.id !== order.id;
    });
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  get serviceTypes() {
    return this._serviceTypes;
  }

  get activityTypes() {
    return this._activityTypes;
  }

  get itemTypes() {
    return this._itemTypes;
  }

  get newOrder(): Order {
    return this._newOrder;
  }

  saveNewOrder(newOrder: Order) {
    this._newOrder = newOrder;
  }

  getServiceTypes() {
    const promesa = new Promise((resolve, reject) => {
      // const url = environment.urlApi + 'ServiceType/getAllServiceTypeActive';
      const url =
        environment.urlApi + 'ServiceType/getAllServiceTypeActiveInactive';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._serviceTypes = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._serviceTypes = [];
            this._serviceTypes.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getActivityTypes(serviceTypeCode: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceType/getAllActivityTypeActiveByServiceType?serviceCode=' +
        serviceTypeCode;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._activityTypes = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._activityTypes = [];
            this._activityTypes.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getItemTypes(
    activityTypeCode: string,
    idServiceType: number,
    idUser: number
  ) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'serviceType/getAllItemTypeActiveByActivityTypeAndServiceTypeAndUser?activityTypeCode=' +
        activityTypeCode +
        '&idServiceType=' +
        idServiceType.toString() +
        '&idUser=' +
        idUser.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._itemTypes = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._itemTypes = [];
            this._itemTypes.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getLocker(lockerNumber: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'locker/getLockerByLockernumber?lockerNumber=' +
        lockerNumber;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            // const alert = await this.alertCtrl.create({
            //   header: 'Aviso',
            //   subHeader:
            //     'No se encontró el número de locker. Ingrese nuevamente el código',
            //   buttons: ['OK']
            // });

            // await alert.present();

            const myToast = this.toastCtrl
              .create({
                message: resultado.message,
                duration: 7000,
                color: 'dark',
                position: 'bottom', // 'top','middle'
                buttons: [
                  {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                      console.log('Cancel clicked');
                    }
                  }
                ]
              })
              .then((toastData) => {
                toastData.present();
                reject();
              });
          } else {
            resolve(resultado.data);
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  getServiceType(id: number) {
    return { ...this._serviceTypes.find((p) => p.idServiceType === id) };
  }

  removeItemType(item: OrderDetail) {
    const index = this.newOrder.detalle.indexOf(item);

    if (index > -1) {
      this.newOrder.detalle.splice(index, 1);
      this.sumOrderTotalAmount();
    }
  }

  sumOrderTotalAmount() {
    let sumTotal = 0;
    this.newOrder.detalle.forEach((element) => {
      let subtotal: number = element.cantidad * element.itemType.unitPrice;

      if (subtotal < element.itemType.baseTariff) {
        subtotal = element.itemType.baseTariff;
      }

      sumTotal = sumTotal + subtotal;
    });
    this.newOrder.total = sumTotal;
  }

  getOrderStatus() {
    return this._service_order_status;
  }

  getAllOrderStatus() {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi + 'ServiceOrder/getAllServiceOrderStatusForApp';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._service_order_status = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._service_order_status = [];
            this._service_order_status.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  declineOrder(id: number, comment: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceOrder/cancelSO?idServiceOrder=' +
        id.toString() +
        '&comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  endOrder(id: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceOrder/confirmSO?idServiceOrder=' +
        id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService._token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve(null);
        }
      );
    });

    return promesa;
  }
}
