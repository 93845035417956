import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() boton: string;
  public logo = 'assets/images/alert/check.png';

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  onSelect() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
