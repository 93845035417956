// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDwlqb8YRpKwR1JRTWwVJkYh-kT1nmmlPM',
    authDomain: 'home-lockers.firebaseapp.com',
    databaseURL: 'https://home-lockers.firebaseio.com',
    projectId: 'home-lockers',
    storageBucket: 'home-lockers.appspot.com',
    messagingSenderId: '410555108461',
    appId: '1:410555108461:web:5dabe43d7503074a6f0364',
    measurementId: 'G-KNHXPBC762'
  },
  // urlApi: 'https://dev-api.home-lockers.com/api/',
  // urlApi: 'https://api.home-lockers.com/api/',
  urlApi: 'https://test-api.home-lockers.com/api/',
  gmapKey: 'AIzaSyDQ7hSPsP2fbwR949Hfmi9COUVEaIdRgQM',
  onesignal_appid: '40bca1d3-d715-473e-bc6b-a1cae05a9cb9'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
