import { ItemType } from './item-type.model';
export class OrderDetail {
  // Campos para mostrar detalle de la orden - Solo lectura
  public idWorkOrderDetail: number;
  public idActivityType: number;
  public activityTypeCode: string;
  public activityTypeDescription: string;
  public idServiceType: number;
  public serviceTypeCode: string;
  public serviceTypeName: string;
  public serviceTypeIcon: string;
  public idItemType: number;
  public itemTypeShortDescription: string;
  public itemTypeDescription: string;
  public quantity: number;
  public idTariffDetail: number;
  public unitPrice: number;
  public baseTariff: number;
  public totalPrice: number;

  // Campos usados durante el registro de la orden
  public id: number;
  public itemType: ItemType;
  public cantidad: number;
  public preciounitario: number;
  public descripcion: string;

  public constructor(init?: Partial<OrderDetail>) {
    Object.assign(this, init);
  }
}
