import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';

// import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsComponent } from './components/items/items.component';
import { CreditCardsComponent } from './components/credit-cards/credit-cards.component';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AlertComponent } from './components/alert/alert.component';
import { CreditCardsNewComponent } from './components/credit-cards-new/credit-cards-new.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { InfoComponent } from './components/info/info.component';

@NgModule({
  declarations: [
    AppComponent,
    ItemsComponent,
    CreditCardsComponent,
    CreditCardsNewComponent,
    AlertComponent,
    InfoComponent
  ],
  entryComponents: [
    ItemsComponent,
    CreditCardsComponent,
    CreditCardsNewComponent,
    AlertComponent,
    InfoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    IonicModule.forRoot({
      backButtonText: '',
      backButtonIcon: 'arrow-back-outline',
      mode: 'ios'
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCElmdQn3JOF3UIBUQJ5vo71L5Y335i5EI'
      apiKey: environment.gmapKey
    })
    // ,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule, // firestore
    // AngularFireAuthModule, // auth
    // AngularFireStorageModule // storage
  ],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    OneSignal,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
