import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { User } from '../models/user.model';
import { UserCard } from '../models/user-card.model';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // tslint:disable-next-line:variable-name
  private _userIsAuthenticated = false;
  // tslint:disable-next-line:variable-name
  private _user: User;
  public _token = '';
  private _username = '';
  public _tokenFCM = '';
  public _userIdOneSignal = '';
  public _tiposDocumentos: any[] = [];

  get userIsAuthenticated() {
    return this._userIsAuthenticated;
  }

  constructor(
    private storage: Storage,
    private router: Router,
    private http: HttpClient,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) {
    this.cargar_storage().then(() => {
      if (this.userIsAuthenticated) {
        console.log('Carga datos de usuario');
        // this.getMyUserData();
        this.getMyUserData()
          .then((userData) => {
            console.log('cargo datos del usuario');
            return this.getMetodosPago();
          })
          .then((tarjetas) => {
            console.log('cargo metodos de pago');
          });
      }
    });
  }

  login(username: string, password: string) {
    // this.addFakeData();

    let promesa = new Promise((resolve, reject) => {
      let data = new HttpParams();
      data = data.append('grant_type', 'password');
      data = data.append('username', username);
      data = data.append('password', password);
      data = data.append('originApp', 'C'); // P para DELIVERY APP

      // let postData = {
      //   "email": correo,
      //   "password": contrasena
      // }

      let url = environment.urlApi + 'token';
      // this.http.post(url, postData)
      this.http.post(url, data).subscribe(
        async (res: any) => {
          console.log(res);

          console.log(res.access_token);

          this._userIsAuthenticated = true;
          this._token = res.access_token;
          this._username = username;
          await this.guardar_storage();
          resolve();
        },
        async (error: any) => {
          // this.activo = this.checkActivo();
          // console.log(JSON.parse(error.error_description));
          // console.log(error.error.error_description);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: error.error.error_description, // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          // console.log(error);
          // console.log(error.error.error);

          resolve();
        }
      );
    });

    return promesa;
  }

  async logout() {
    this._userIsAuthenticated = false;
    this._user = null;
    await this.guardar_storage();
    this.router.navigateByUrl('/auth');
  }

  register(usuario: any) {
    let promesa = new Promise((resolve, reject) => {
      // let postData = {
      //   idUser: 0,
      //   userAccount: 'jvalencia3005@gmail.com',
      //   password: 'string',
      //   firstName: 'string',
      //   lastName: 'string',
      //   email: 'string',
      //   phone: 'string',
      //   mobile: 'string',
      //   tokenFCM: 'string',
      //   rolType: 'C',
      //   isActive: false,
      //   rowStatus: true
      // };

      let url = environment.urlApi + 'user/create';
      // this.http.post(url, postData)
      this.http.post(url, usuario).subscribe(
        async (res: any) => {
          // console.log(res);

          // console.log(res.isValid);

          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se registro satisfactoriamente
            resolve();
          }
        },
        async (error: any) => {
          // this.activo = this.checkActivo();

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          reject();
        }
      );
    });

    return promesa;
  }

  update(usuario: any) {
    let promesa = new Promise((resolve, reject) => {
      let url = environment.urlApi + 'user/update';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.post(url, usuario, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);

          // console.log(res.isValid);

          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se actualizo satisfactoriamente
            resolve();
          }
        },
        async (error: any) => {
          // this.activo = this.checkActivo();

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          reject();
        }
      );
    });

    return promesa;
  }

  delete() {
    let promesa = new Promise((resolve, reject) => {
      let url = environment.urlApi + 'user/delete/' + this._user.idUser;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);

          // console.log(res.isValid);

          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se actualizo satisfactoriamente
            this._userIsAuthenticated = false;
            this._user = null;
            await this.guardar_storage();
            resolve();
          }
        },
        async (error: any) => {
          // this.activo = this.checkActivo();

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          reject();
        }
      );
    });

    return promesa;
  }

  resetPassword(email: string) {
    let promesa = new Promise((resolve, reject) => {
      let url =
        environment.urlApi + 'security/passwordRecovery?userAccount=' + email;

      this.http.get(url).subscribe(
        async (res: any) => {
          console.log(res);

          if (!res.isValid) {
            // const mensajeError = res.brokenRulesCollection[0].description;
            const alert = await this.alertCtrl.create({
              header: 'Alerta',
              subHeader: res.message,
              buttons: ['OK']
            });
            await alert.present();

            reject();
          } else {
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en provider', error);

          const alert = await this.alertCtrl.create({
            header: 'Alerta',
            subHeader: 'La cuenta de correo no se encuentra registrada', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject('La cuenta de correo no se encuentra registrada');
        }
      );
    });

    return promesa;
  }

  // addFakeData() {
  //   this._user = {
  //     idUser: 1,
  //     userAccount: 'calebsc@gmail.com',
  //     firstName: 'Caleb',
  //     lastName: 'Santos Calderón',
  //     email: 'calebsc@gmail.com',
  //     password: '123456',
  //     phone: '2344555',
  //     mobile: '99999999',
  //     birthDate: new Date('1972-09-25'),
  //     originApp: 'C',
  //     // idBusinessPartner: '',
  //     idUserDocumentType: 6,
  //     documentNumber: '09869589',
  //     tarjetas: [
  //       {
  //         id: 1,
  //         cardname: 'Caleb Santos Calderón',
  //         cardnumber: '**** **** **** 4576',
  //         cardcvv: '123',
  //         cardexpdate: new Date('2025-12-31'),
  //         active: false,
  //         token: 'token123'
  //       },
  //       {
  //         id: 2,
  //         cardname: 'Caleb Santos Calderón',
  //         cardnumber: '**** **** **** 3433',
  //         cardcvv: '123',
  //         cardexpdate: new Date('2025-12-31'),
  //         active: false,
  //         token: 'token123'
  //       },
  //       {
  //         id: 3,
  //         cardname: 'Caleb Santos Calderón',
  //         cardnumber: '**** **** **** 5467',
  //         cardcvv: '123',
  //         cardexpdate: new Date('2025-12-31'),
  //         active: true,
  //         token: 'token123'
  //       }
  //     ]
  //   };
  // }

  getMyUserData() {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'user/getByUserAccount?userAccount=' +
        this._username;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: 'No se encontraron los datos del usuario',
              buttons: ['OK']
            });
            await alert.present();
            resolve();
          } else {
            console.log(resultado.data);
            // si se obtienen los datos del usuario
            this._user = {
              idUser: resultado.data.idUser,
              userAccount: resultado.data.userAccount,
              firstName: resultado.data.firstName,
              lastName: resultado.data.lastName,
              email: resultado.data.email,
              password: resultado.data.password,
              phone: resultado.data.phone,
              mobile: resultado.data.mobile,
              birthDate: resultado.data.birthDate,
              originApp: 'C',
              idBusinessPartner: resultado.data.idBusinessPartner,
              idUserDocumentType: resultado.data.idUserDocumentType,
              documentNumber: resultado.data.documentNumber,
              tarjetas: []
            };

            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getTiposDocumentos() {
    let promesa = new Promise((resolve, reject) => {
      let url =
        environment.urlApi + 'MasterData/GetMasterTablesByEntityCode/UDT';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._tiposDocumentos = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._tiposDocumentos = [];
            this._tiposDocumentos.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getMetodosPago() {
    let promesa = new Promise((resolve, reject) => {
      let url =
        environment.urlApi +
        'creditcard/getCreditCardsByUser/' +
        this._user.idUser;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._user.tarjetas = [];
            resolve();
          } else {
            // si se obtienen los datos de las tarjetas
            this._user.tarjetas = [];
            resultado.data.forEach((element) => {
              if (element.rowStatus) {
                const tarjeta: UserCard = {
                  id: element.idCreditCard,
                  cardnumber: element.creditCardNumber,
                  cardexpdate: element.expirationDate,
                  cardcvv: '', // element.securityCode.trim(),
                  cardname: element.creditCardName,
                  active: element.isDefault,
                  token: element.tokenCard,
                  cardBrand: element.cardBrand
                };
                this._user.tarjetas.push(tarjeta);
              }
            });
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          // const alert = await this.alertCtrl.create({
          //   header: 'Aviso',
          //   subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
          //   buttons: ['OK']
          // });

          // await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getUser() {
    return this._user;
  }

  getCreditCard() {
    const tarjetas: UserCard[] = this._user.tarjetas;
    return { ...tarjetas.find((p) => p.active === true) };
  }

  getCreditCards() {
    // const tarjetas: UserCard[] = this._user.tarjetas;
    return this._user.tarjetas;
  }

  // addCreditCard(card: UserCard) {
  //   this._user.tarjetas.forEach((element) => {
  //     element.active = false;
  //   });

  //   card.id = this._user.tarjetas.length + 1;
  //   this._user.tarjetas.push(card);
  // }

  addCreditCard(card: any) {
    let promesa = new Promise((resolve, reject) => {
      let url = environment.urlApi + 'creditcard/create';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.post(url, card, httpOptions).subscribe(
        async (res: any) => {
          console.log(res);
          // console.log(res.isValid);
          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se registro satisfactoriamente
            resolve(resultado.data);
          }
        },
        async (error: any) => {
          console.log(error.error);

          const resultado = error.error;
          const mensajeError = resultado.brokenRulesCollection[0].description;

          const alert = await this.alertCtrl.create({
            header: 'Alerta',
            subHeader: mensajeError, // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  // updateDefaultCreditCard(card: UserCard) {
  //   if (card.active) {
  //     this._user.tarjetas.forEach((element) => {
  //       if (card.id === element.id) {
  //         element.active = true;
  //       } else {
  //         element.active = false;
  //       }
  //     });
  //   }
  // }

  updateDefaultCreditCard(idCreditCard: number) {
    let promesa = new Promise((resolve, reject) => {
      let url =
        environment.urlApi +
        'creditcard/setDefaultCreditCard?idUser=' +
        this._user.idUser +
        '&idCreditCard=' +
        idCreditCard;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          // console.log(res.isValid);
          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se registro satisfactoriamente
            resolve();
          }
        },
        async (error: any) => {
          // this.activo = this.checkActivo();

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          reject();
        }
      );
    });

    return promesa;
  }

  deleteCreditCard(id: number) {
    let promesa = new Promise((resolve, reject) => {
      let url = environment.urlApi + 'creditcard/delete/' + id;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);

          // console.log(res.isValid);

          const resultado = res;

          if (!resultado.isValid) {
            // console.log(resultado.brokenRulesCollection);
            const mensajeError = resultado.brokenRulesCollection[0].description;
            // console.log(mensajeError);

            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: mensajeError,
              buttons: ['OK']
            });
            await alert.present();
            reject();
          } else {
            // si se actualizo satisfactoriamente
            resolve();
          }
        },
        async (error: any) => {
          // this.activo = this.checkActivo();

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo cargar la información.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          reject();
        }
      );
    });

    return promesa;
  }

  registerPushToken() {
    let promesa = new Promise(async (resolve, reject) => {
      let url =
        environment.urlApi + 'UserDeviceApp/CreateOrUpdateUserDeviceApp';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this._token
        })
      };

      this._tokenFCM = await this.storage.get('tokenFCM');
      this._userIdOneSignal = await this.storage.get('userIdOneSignal');
      console.log(
        'valores de storage antes de registrar token:',
        this._tokenFCM,
        this._userIdOneSignal
      );

      const datos = {
        idUserDeviceApp: 0,
        idUser: this._user.idUser,
        idDevice: this._userIdOneSignal,
        sessionToken: this._tokenFCM,
        application: 'CUS'
      };
      console.log('datos de onesignal antes de ejecutar endpoint', datos);

      this.http.post(url, datos, httpOptions).subscribe(
        async (res: any) => {
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: resultado.message,
              buttons: ['OK']
            });
            await alert.present();
            resolve();
          } else {
            // si se actualizo satisfactoriamente
            resolve();
          }
        },
        async (error: any) => {
          // const alert = await this.alertCtrl.create({
          //   header: 'Aviso',
          //   subHeader: 'No se pudo activar el envío de alertas.', // JSON.stringify(error),
          //   buttons: ['OK']
          // });
          // await alert.present();
          // console.log(error);
          // // console.log(error.error.error);

          console.log('No se pudo activar el envío de alertas.');

          resolve();
        }
      );
    });

    return promesa;
  }

  cargar_storage() {
    let promesa = new Promise((resolve, reject) => {
      // console.log('Inicializando storage');

      this.storage.get('login').then((val) => {
        if (val) {
          this._userIsAuthenticated = val;
        }
      });
      this.storage.get('token').then((val) => {
        if (val) {
          this._token = val;
        }
      });
      this.storage.get('tokenFCM').then((val) => {
        if (val) {
          this._tokenFCM = val;
        }
      });
      this.storage.get('username').then((val) => {
        if (val) {
          this._username = val;
        }
        resolve();
      });
    });

    return promesa;
  }

  private async guardar_storage() {
    if (this._userIsAuthenticated) {
      await this.storage.set('login', this._userIsAuthenticated);
      await this.storage.set('token', this._token);
      // await this.storage.set('tokenFCM', this._tokenFCM);
      await this.storage.set('username', this._username);
    } else {
      await this.storage.remove('login');
      await this.storage.remove('token');
      // await this.storage.remove('tokenFCM');
      await this.storage.remove('username');
      // this.storage.remove('intro-seen');
    }
  }
}
