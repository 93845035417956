import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from '../../environments/environment';
import { AlertController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  userId: string;

  constructor(
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private authService: AuthService,
    private storage: Storage
  ) {}

  setupPush() {
    // this.oneSignal.setRequiresUserPrivacyConsent(true);
    // this.oneSignal
    //   .promptForPushNotificationsWithUserResponse()
    //   .then((accepted) => {
    //     console.log('User accepted notifications:', accepted);
    //   });

    // this.oneSignal.userProvidedPrivacyConsent((providedConsent) => {
    //   this.oneSignal.provideUserConsent(true);
    // });
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit(
      environment.onesignal_appid,
      environment.firebaseConfig.messagingSenderId
    );

    this.oneSignal.inFocusDisplaying(
      // this.oneSignal.OSInFocusDisplayOption.None
      this.oneSignal.OSInFocusDisplayOption.InAppAlert
    );

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe((data) => {
      console.log('Notificación recibida', data);
      // let msg = data.payload.body;
      // let title = data.payload.title;
      // let additionalData = data.payload.additionalData;
      // this.showAlert(title, msg, additionalData.task);
    });

    // // Set your iOS Settings
    // const iosSettings = {
    //   kOSSettingsKeyAutoPrompt: false,
    //   kOSSettingsKeyInAppLaunchURL: false
    // };

    // this.oneSignal.iOSSettings(iosSettings);

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe((data) => {
      console.log('Notificación abierta', data);
      // Just a note that the data is a different place here!
      // let additionalData = data.notification.payload.additionalData;
      // this.showAlert(
      //   'Notification opened',
      //   'You already read this before',
      //   additionalData.task
      // );
    });

    this.oneSignal.getIds().then(async (info) => {
      const tokenFCM = await this.storage.get('tokenFCM');
      const userIdOneSignal = await this.storage.get('userIdOneSignal');

      console.log(
        'valores de storage dentro de getIds:',
        tokenFCM,
        userIdOneSignal
      );

      this.userId = info.userId;
      if (!tokenFCM) {
        await this.storage.set('tokenFCM', info.userId);
        await this.storage.set('userIdOneSignal', info.pushToken);
        this.authService._tokenFCM = info.userId;
        this.authService._userIdOneSignal = info.pushToken;
      } else {
        this.authService._tokenFCM = tokenFCM;
        this.authService._userIdOneSignal = userIdOneSignal;
      }
      // console.log('Obtener Id del Suscriptor', info);
      console.log(
        'Obtener Id del Suscriptor',
        this.authService._tokenFCM,
        this.authService._userIdOneSignal
      );
    });

    // this.oneSignal.getPermissionSubscriptionState().then((status) => {
    //   this.userId = status.subscriptionStatus.userId;
    //   this.authService._tokenFCM = status.subscriptionStatus.userId;
    //   this.authService._userIdOneSignal = status.subscriptionStatus.pushToken;
    //   console.log('Obtener Id del Suscriptor', status);
    // });

    this.oneSignal.endInit();
  }

  // async showAlert(title, msg, task) {
  //   const alert = await this.alertCtrl.create({
  //     header: title,
  //     subHeader: msg,
  //     buttons: [
  //       {
  //         text: `Action: ${task}`,
  //         handler: () => {
  //           // E.g: Navigate to a specific screen
  //         }
  //       }
  //     ]
  //   });
  //   alert.present();
  // }
}
