import { Component, OnInit, Input } from '@angular/core';
import { UserCard } from '../../models/user-card.model';
import { AuthService } from '../../services/auth.service';
import {
  IonItemSliding,
  AlertController,
  LoadingController
} from '@ionic/angular';
import { SegmentChangeEventDetail } from '@ionic/core';
import { ToastController, ModalController } from '@ionic/angular';
import { Order } from '../../models/order.model';

@Component({
  selector: 'app-credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.scss']
})
export class CreditCardsComponent implements OnInit {
  @Input() newOrder: Order;
  public isUserDataLoaded = false;
  cards: UserCard[] = [];
  canDelete = false;

  constructor(
    private authService: AuthService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    console.log('ionViewWillEnter');
    this.doLoadPaymentMethods();
  }

  async doLoadPaymentMethods() {
    const loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Cargando datos...'
    });
    await loading.present();

    this.authService
      .getMyUserData()
      .then((userData) => {
        console.log('cargo datos del usuario');
        return this.authService.getMetodosPago();
      })
      .then((tarjetas) => {
        console.log('cargo metodos de pago');
        this.cards = this.authService.getCreditCards();
        this.canDelete = this.cards.length > 1 ? true : false;

        this.isUserDataLoaded = true;
        loading.dismiss();
      });
  }

  onDeleteCard(card: UserCard, slidingEl: IonItemSliding) {
    slidingEl.close();

    const alert = this.alertCtrl
      .create({
        header: '¿Deseas eliminar tu tarjeta?',
        message: 'Recuerda que no puedes deshacer este paso.',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'Si',
            handler: () => {
              console.log('Confirm Okay');
              this.onDelete(card);
            }
          }
        ]
      })
      .then((actionSheetEl) => {
        actionSheetEl.present();
      });
  }

  async onUpdateDefaultCard(
    event: CustomEvent<SegmentChangeEventDetail>,
    card: UserCard
  ) {
    const loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Procesando registro...'
    });
    await loading.present();

    this.authService
      .updateDefaultCreditCard(card.id)
      .then(() => {
        return this.authService.getMetodosPago();
      })
      .then(() => {
        this.cards = this.authService.getCreditCards();
        loading.dismiss();

        const myToast = this.toastCtrl
          .create({
            message: 'Su método de pago predeterminado ha sido actualizado',
            duration: 7000,
            color: 'dark',
            position: 'bottom', // 'top','middle'
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          .then((toastData) => {
            toastData.present();
            // this.router.navigateByUrl('/payment-methods');
          });
      })
      .catch((error) => {
        this.cards = this.authService.getCreditCards();
        loading.dismiss();

        const myToast = this.toastCtrl
          .create({
            message: 'No se pudo actualizar su método de pago predeterminado',
            duration: 7000,
            color: 'dark',
            position: 'bottom', // 'top','middle'
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          .then((toastData) => {
            toastData.present();
          });
      });
  }

  async onDelete(card: UserCard) {
    const loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Eliminando método de pago...'
    });
    await loading.present();

    this.authService
      .deleteCreditCard(card.id)
      .then(() => {
        return this.authService.getMetodosPago();
      })
      .then(() => {
        // this.cards = this.cards.filter((item) => {
        //   return item.id !== card.id;
        // });
        // this.canDelete = this.cards.length > 1 ? true : false;
        this.cards = this.authService.getCreditCards();
        // console.log(this.cards);
        loading.dismiss();

        const myToast = this.toastCtrl
          .create({
            message: 'Se eliminó el método de pago',
            duration: 7000,
            color: 'dark',
            position: 'bottom', // 'top','middle'
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          .then((toastData) => {
            toastData.present();
            // this.router.navigateByUrl('/payment-methods');
          });
      })
      .catch((error) => {
        loading.dismiss();
        const myToast = this.toastCtrl
          .create({
            message: 'No se pudo eliminar el método de pago',
            duration: 7000,
            color: 'dark',
            position: 'bottom', // 'top','middle'
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          .then((toastData) => {
            toastData.present();
          });
      });
  }

  onSelect() {
    this.newOrder.usercard = this.authService.getCreditCard();
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
      openModalRegister: false
    });
  }

  registerCreditCard() {
    this.newOrder.usercard = this.authService.getCreditCard();
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
      openModalRegister: true
    });
  }
}
