import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: Storage
  ) {}

  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]
  // ): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    const hasSeen = await this.storage.get('intro-seen');

    if (!hasSeen) {
      this.router.navigateByUrl('/introduction');
    }

    return hasSeen;
  }
}
