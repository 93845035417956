import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { Order } from '../../models/order.model';
import { ActivityType } from '../../models/activity-type.model';
import { OrdersService } from '../../services/orders.service';
import { ItemType } from '../../models/item-type.model';
import { OrderDetail } from '../../models/order-detail.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @Input() activityType: ActivityType;
  @Input() newOrder: Order;
  public isUserDataLoaded = false;

  public items: any = [];

  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private ordersService: OrdersService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    console.log('ionViewWillEnter');

    const loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Cargando datos...'
    });
    await loading.present();

    this.ordersService
      .getItemTypes(
        this.activityType.code,
        this.activityType.idServiceType,
        this.authService.getUser().idUser
      )
      .then(() => {
        // console.log(this.ordersService.itemTypes);
        this.ordersService.itemTypes
          .sort((a, b) => a.description.localeCompare(b.description))
          .forEach((element) => {
            const item: any = {
              itemType: element,
              val: element.description,
              price: element.unitPrice,
              isChecked: false,
              isDisabled: this.isSelected(element)
            };

            this.items.push(item);
          });

        this.isUserDataLoaded = true;
        loading.dismiss();
      });
  }

  isSelected(itemType: ItemType) {
    // console.log(this.newOrder.detalle);
    return this.newOrder.detalle.find(
      (p) => p.itemType.idItemType === itemType.idItemType
    )
      ? true
      : false;
  }

  onDismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
      addCart: false
    });
  }

  onSelect() {
    this.items.forEach((element) => {
      if (element.isChecked) {
        const detalle = new OrderDetail();
        detalle.itemType = element.itemType;
        if (detalle.itemType.unitPrice == null) {
          detalle.itemType.unitPrice = 0;
        }
        detalle.cantidad = 1;
        // detalle.preciounitario = element.itemType.unitPrice;
        detalle.descripcion = element.val;
        this.newOrder.detalle.unshift(detalle);
        this.ordersService.sumOrderTotalAmount();
      }
    });
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
      addCart: true
    });
  }
}
