import { OrderDetail } from './order-detail.model';
import { ServiceType } from './service-type.model';
import { Locker } from './locker.model';
import { UserCard } from './user-card.model';
export class Order {
  // Campos devueltos en el listado
  public idServiceOrder: number;
  public serviceOrderCode: string;
  public idServiceType: number;
  public serviceTypeCode: string;
  public serviceTypeName: string;
  public serviceTypeIcon: string;
  public idCurrentServiceOrderStatus: number;
  public currentServiceOrderStatusCode: string;
  public currentServiceOrderStatusDescription: string;
  public groupEntityStatus: string;
  public modifiedDate: Date;

  // Campos adicionales devueltos al consultar detalle de la orden
  public idUser: number;
  public idLockerRegister: number;
  public lockerNumber: string;
  public lockerPassword: string;
  public idLockerComplete: number;
  public lockerNumberComplete: string;
  public lockerPasswordComplete: string;
  public workOrderDetails: OrderDetail[];

  // Campos usados para registrar una orden
  public id: number;
  public codigo: string;
  public fechapedido: Date;
  public locker: Locker;
  public clave: string;
  public usercard: UserCard;
  public numerotarjeta: string;
  public tokentarjeta: string;
  public total: number;
  public estado: string;
  public icono: string;
  public serviceType: ServiceType;
  public detalle: OrderDetail[];

  public constructor(init?: Partial<Order>) {
    Object.assign(this, init);
  }
}
