import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: Storage
  ) {}

  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]
  // ): Observable<boolean> | Promise<boolean> | boolean {
  //   if (!this.authService.userIsAuthenticated) {
  //     this.router.navigateByUrl('/auth');
  //   }
  //   return this.authService.userIsAuthenticated;
  // }
  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    const hasLogin = await this.storage.get('login');

    if (!hasLogin) {
      this.router.navigateByUrl('/auth');
    }

    return hasLogin;

    // this.authService.cargar_storage();
    // if (!this.authService.userIsAuthenticated) {
    //   this.router.navigateByUrl('/auth');
    // }
    // return this.authService.userIsAuthenticated;
  }
}
