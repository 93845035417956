import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  LoadingController,
  ToastController,
  ModalController
} from '@ionic/angular';
import { Router } from '@angular/router';
import { CreditCardValidators } from 'angular-cc-library';
import { UserCard } from '../../models/user-card.model';
import { AuthService } from '../../services/auth.service';
import { Order } from '../../models/order.model';

@Component({
  selector: 'app-credit-cards-new',
  templateUrl: './credit-cards-new.component.html',
  styleUrls: ['./credit-cards-new.component.scss']
})
export class CreditCardsNewComponent implements OnInit {
  @Input() newOrder: Order;
  minDate: string = new Date().toISOString();
  maxDate: any = new Date().getFullYear() + 5;
  card: UserCard;
  form: FormGroup;
  customDayShortNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  masks: any;
  cardNumber: any = '';
  cardExpiry: any = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      cardnumber: new FormControl(null, {
        // updateOn: 'blur',
        validators: [
          Validators.required,
          CreditCardValidators.validateCCNumber
          // Validators.minLength(13),
          // Validators.maxLength(19),
          // Validators.pattern('[0-9]{0,19}')
        ]
      }),
      cardexpdate: new FormControl(null, {
        // updateOn: 'blur',
        validators: [
          Validators.required
          // Validators.minLength(9),
          // CreditCardValidators.validateExpDate
        ]
      }),
      cardcvv: new FormControl(null, {
        // updateOn: 'blur',
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4)
        ]
      }),
      cardname: new FormControl(null, {
        // updateOn: 'blur',
        validators: [
          Validators.required,
          Validators.pattern('[a-zA-Z\u00C0-\u00FF ]*')
        ]
      }),
      cardbrand: new FormControl(null, {
        // updateOn: 'blur',
        validators: [
          // Validators.required
        ]
      })
    });
  }

  async onCreateCard() {
    if (!this.form.valid) {
      return;
    }

    // console.log(
    //   this.form.value.cardbrand,
    //   this.form.value.cardnumber.replace(/\s/g, '')
    // );

    let numtarjeta: string = this.form.value.cardnumber.replace(/\s/g, '');

    // console.log(numtarjeta);
    // console.log('**** **** **** ' + numtarjeta.substr(numtarjeta.length - 4));

    // DESBLOQUEAR ESTE CODIGO
    // numtarjeta = '**** **** **** ' + numtarjeta.substr(numtarjeta.length - 4);

    //  +
    // ' ' +
    // this.form.value.cardbrand;
    // console.log(numtarjeta);
    // return;

    const nuevaTarjeta = {
      idCreditCard: 0,
      idUser: this.authService.getUser().idUser,
      tokenCard: 'tokenABC',
      creditCardNumber: numtarjeta,
      creditCardName: this.form.value.cardname,
      securityCode: this.form.value.cardcvv,
      expirationDate: this.form.value.cardexpdate,
      cardBrand: this.form.value.cardbrand,
      isDefault: false
    };

    const loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Procesando registro...'
    });
    await loading.present();

    this.authService
      .addCreditCard(nuevaTarjeta)
      .then((data: any) => {
        return this.authService.updateDefaultCreditCard(data.idCreditCard);
      })
      .then((data: any) => {
        return this.authService.getMetodosPago();
      })
      .then(() => {
        this.newOrder.usercard = this.authService.getCreditCard();
        loading.dismiss();

        const myToast = this.toastCtrl
          .create({
            message: 'Método de pago registrado satisfactoriamente',
            duration: 7000,
            color: 'dark',
            position: 'bottom', // 'top','middle'
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          .then((toastData) => {
            toastData.present();
            this.closeModal();
          });
      })
      .catch((error) => {
        loading.dismiss();

        // const myToast = this.toastCtrl
        //   .create({
        //     message: 'No se pudo registrar el método de pago',
        //     duration: 7000,
        //     color: 'dark',
        //     position: 'bottom', // 'top','middle'
        //     buttons: [
        //       {
        //         text: 'Ok',
        //         role: 'cancel',
        //         handler: () => {
        //           console.log('Cancel clicked');
        //         }
        //       }
        //     ]
        //   })
        //   .then((toastData) => {
        //     toastData.present();
        //   });
      });

    // this.form.value.cardnumber =
    //   '**** **** **** ' +
    //   this.form.value.cardnumber.substr(this.form.value.cardnumber.length - 4);
    // this.authService.addCreditCard(this.card);
    // this.router.navigateByUrl('/payment-methods');
  }

  closeModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
