export class ActivityType {
  public idActivityType: number;
  public idServiceType: number;
  public code: string;
  public description: string;
  public serviceTypeCode: string;
  public serviceTypeName: string;
  public serviceTypeIcon: string;
  public isDefault: boolean;
  public isOptional: boolean;
  public icon: string;
  public information: string;

  public constructor(init?: Partial<ActivityType>) {
    Object.assign(this, init);
  }
}
